/*=============== GOOGLE FONTS ===============*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&family=Ubuntu:wght@300;400;600&display=swap");
/*=============== VARIABLES CSS ===============*/
:root {
  --header-height: 3rem;

  /*========== Colors ==========*/
  /* Change favorite color to match images */
  /*Green dark 190 - Green 171 - Grren Blue 200*/


  /* HSL color mode */
  --first-color: #282d3d;
  --first-color-second: #ff3200;
  --first-color-alt: rgb(80, 83, 94);
  --gray: #111;
  --white-color: #FFF;
  --background: #DCDCDC;

  --hue: 230;
  --sat: 100%;


  /*========== Font and typography ==========*/
  --body-font: 'Ubuntu', sans-serif;
  --title-font: 'Montserrat', sans-serif;
  --biggest-font-size: 4rem;
  --h1-font-size: 3rem;
  --h2-font-size: 2.6rem;
  --h3-font-size: 1.8rem;
  --h4-font-size: 1.4rem;
  --normal-font-size: 1rem;
  --small-font-size: .813rem;
  --smaller-font-size: .75rem;

  /*========== Font weight ==========*/
  --font-light: 100;
  --font-medium: 400;
  --font-semi-bold: 600;

  /*========== Margenes Bottom ==========*/
  --mb-0-25: .25rem;
  --mb-0-5: .5rem;
  --mb-0-75: .75rem;
  --mb-1: 1rem;
  --mb-1-25: 1.25rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;
  --mb-3: 3rem;

  /*========== z index ==========*/
  --z-tooltip: 10;
  --z-fixed: 100;

  /*========== Hover overlay ==========*/
  --transition: .3s;
  --img-hidden: hidden;
}

@media screen and (max-width: 600px) {
    :root {
        --biggest-font-size: 2rem;
        --h1-font-size: 1.6rem;
        --h2-font-size: 1.25rem;
        --h3-font-size: 1rem;
        --h4-font-size: .95rem;
        --normal-font-size: .875rem;
        --small-font-size: .75rem;
        --smaller-font-size: .613rem;
      }
}

body{
    background-color: var(--white-color) !important;
    color: var(--first-color);
    margin: 0;
    padding: 0;
}

label, input, textarea, button, p, select {
    font-family: var(--body-font);
    font-weight: 400 !important;
    font-size: 1rem !important;
}

strong{
    font-weight: 700 !important;
}

footer{
    padding: 2.6rem 0;
    background-color: var(--white-color);
}

footer h4{
    color: var(--first-color-second);
}

footer ul{
    list-style-type: none;
    padding: 0;
    margin: 0;
}

footer ul li a{
    text-decoration: none;
    font-size: var(--small-font-size);
    font-family: var(--body-font);
    color: var(--first-color);
    transition: all 0.25s ease 0s;
}

footer ul li a:hover{
    color: var(--first-color-second);
}

footer p{
    font-size: var(--small-font-size);
    font-family: var(--body-font);
    color: var(--first-color);
}

.footer__social {
    display: flex;
    column-gap: 1.5rem;
    margin-bottom: 3rem;
}

.footer__social-link {
    font-size: var(--h4-font-size);
    color: var(--background);
    transition: all 0.25s ease 0s;
}

.footer__social-link:hover {
    color: var(--first-color-second);
}

.bg-first-color{
    background-color: var(--first-color);
}

.top_header{
    position: relative;
    z-index: -1;
}

.top_text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    font-size: 20px;
    font-weight: bold;
}

nav{
   background-color: var(--white-color);
}

.navbar-collapse{
    flex-grow: 0 !important;
}

.dropdown-menu {
    display: block;
    border: 0 !important;
    background-color: var(--white-color) !important;
}

.dropdown {
    position: static !important;
}

.dropdown-menu {
    margin-top: 0px !important;
    width: 100% !important;
    border-radius: 0 !important;
    left: 0 !important;
}

.nav-link{
    font-family: var(--body-font) !important;
    font-weight: var(--font-light) !important;
    font-size: var(--h4-font-size) !important;
    color: var(--first-color-second) !important;
    padding-left: var(--mb-1-25) !important;
    padding-right: var(--mb-1-25) !important;
    transition: all 0.25s ease 0s;
    position: relative;
}

.nav-link:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    top: 0;
    left: 0;
    background-color: var(--first-color-second);
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

.nav-link:hover:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}

.dropdown-toggle:after{
    display: none !important;
}

.dropdown-item{
    font-size: var(--normal-font-size) !important;
    font-weight: var(--font-medium);
    color: var(--first-color) !important;
    padding: var(--mb-0-75) var(--mb-0-5) !important;
    border-left: 2px solid var(--white-color) !important;
    transition: 1s;
    margin-left: var(--mb-0-25) !important;
}

.dropdown-item span{
    font-size: var(--small-font-size) !important;
    font-weight: var(--font-light);
    color: var(--first-color-alt) !important;
    font-style: italic;
}

.dropdown-item:hover,
.dropdown-item:focus,
.dropdown-item:active{
    border-left: 2px solid var(--first-color-second) !important;
}

.active-sis{
    background-color: var(--first-color-second) !important;
    color: var(--white-color) !important;
}

.card__content{
    background-color: #DCDCDC;
    padding: var(--mb-0-5);
    box-shadow: 0px 8px 6px hsla(230, 100%, 15%, 0.15);
}

h1{
    font-family: var(--title-font) !important;
    font-weight: var(--font-semi-bold) !important;
    font-size: var(--h1-font-size) !important;
    color: var(--first-color-second) !important;
}

h2{
    font-family: var(--title-font) !important;
    font-weight: var(--font-semi-bold) !important;
    font-size: var(--h2-font-size) !important;
    color: var(--first-color-second) !important;
}

h3{
    font-family: var(--title-font) !important;
    font-weight: var(--font-semi-bold) !important;
    font-size: var(--h3-font-size) !important;
    color: var(--first-color) !important;
}

p{
    font-family: var(--body-font) !important;
    font-weight: var(--font-light) !important;
    font-size: var(--normal-font-size) !important;
    color: var(--first-color) !important;
}

.section {
    padding: 4rem 0;
}

.height_60{
    height: 60vh
}

.height_80{
    height: 80vh
}

.count-text{
    font-family: var(--title-font) !important;
    font-weight: var(--font-light) !important;
    font-size: var(--biggest-font-size) !important;
}

.header_img{
    background: rgba(40, 45, 61, 0.4);
    width: 100%;
    height: 620px;
    position: absolute;
    z-index: 9;
}

.header_img img{
    height: 620px;
}

.section__alt {
    padding: 4.5rem 0 0;
    background-color: var(--first-color);
    height: 40vh;
}

.plans__price{
    background-color: #31343b;
    box-shadow: 0px 2px 6px hsla(var(--hue), 100%, 15%, 0.15);
    padding: 2rem 1.5rem;
    border-radius: 1rem;
    text-align: center;
    z-index: 1;
}

.plans__description{
    background-color: #DCDCDC;
    box-shadow: 0px 2px 6px hsla(var(--hue), 100%, 15%, 0.15);
    padding: 2rem 1.5rem;
    border-radius: 1rem;
    margin-top: -40px;
    text-align: center;
}

.btn-w{
    border-radius: 20px !important;
    background-color: var(--first-color-second) !important;
    color: var(--white-color) !important;
    font-size: var(--normal-font-size) !important;
    font-weight: var(--font-light) !important;
    padding: 8px 28px !important;
}

.btn-ws{
    border-radius: 20px !important;
    background-color: var(--first-color-second) !important;
    color: var(--white-color) !important;
    font-size: 1rem !important;
    font-weight: var(--font-light) !important;
    padding: 6px 18px !important;
}

.hr_title{
    background-color: var(--first-color) !important;
    height: 3px;
    width: 15%;
}

.img_solution {
    display: inline-block;
    position: relative;
}

.img_solution img{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 50px;
    box-shadow: 0px 2px 6px hsla(var(--hue), 100%, 15%, 0.15);
}

.img_solution figcaption {
    position: absolute;
    top: 130px;
    padding: 10px 40px;
    font-size: var(--h1-font-size);
    font-family: var(--body-font);
    font-weight: var(--font-semi-bold);
    color: var(--first-color-second);
    background-color: rgba(40, 45, 61, 0.4);
}

.div__home{
    position: relative;
    bottom: 2.8rem;
    right: 2rem;
    padding: 12px;
    border-radius: 10px;
    z-index: 5;
    font-weight: var(--font-medium) !important;
    font-size: var(--normal-font-size) !important;
    color: var(--first-color-second) !important;
    background-color: #DCDCDC;
    box-shadow: 0px 2px 6px hsla(var(--hue), 100%, 15%, 0.15);
}

.div__home span{
    font-weight: var(--font-medium) !important;
    font-size: var(--normal-font-size) !important;
    color: var(--first-color) !important;
}

.logo-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 20px;
    background-color: #222; /* Fundo escuro para contraste */
}

.logo-container img {
    width: 150px; /* Tamanho fixo dos logos */
    height: auto;
    filter: brightness(0) invert(1); /* Transforma imagens coloridas em brancas */
    object-fit: contain; /* Mantém proporção do logo */
    transition: transform 0.3s ease-in-out;
}

.logo-container img:hover {
    transform: scale(1.1); /* Efeito de zoom ao passar o mouse */
}

@media (max-width: 600px) {
    .logo-container img {
        width: 100px; /* Ajuste para telas menores */
    }
}

.item img{
    height: 460px;
}

@keyframes floathome {

    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    50% {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

}

.parallax_home{
    background-image: url('/images/home/sistema-html-para-o-conceito-de-site_pk.jpg');
    min-height: 420px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.parallax_background{
    background-color: rgb(40, 45, 61, 0.3);
    min-height: 420px;
    width: 100%;
    z-index: 1;
}

.d-none{
    display: none;
}

.d-block{
    display: block;
}

@media screen and (min-width: 600px) {
    .d-none{
        display: block;
    }

    .d-block{
        display: none;
    }
}

.card__digital_iv,
.card__digital{
    float:left;
    width: 460px !important;
    height: 300px;
    border-style: solid;
}

.card__digital{
    border-color: #000 #00f #dcd #dcd;
    border-width: 0 260px 300px 160px;
}

.card__digital_iv{
    border-color: #000 #dcd #00f #00f;
    border-width: 0 260px 300px 160px;
}

.digital_tx{
    position: relative;
    z-index: 1;
    width: 300px;
    padding: 42px 18px;
    right: 150px
}
